import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PSI University Offers 2020 - Our 25 graduates have amassed 77 university acceptances across 8 countries. PSI does not limit the number of universities where students may apply.  Three students received renewable scholarships together totalling $169,500 USD.  Fourteen students received acceptance offers from their top choice university.    `}</p>
    <p>{`Austria`}<br parentName="p"></br>{`
`}{`Webster Vienna Private University`}</p>
    <p>{`Canada`}<br parentName="p"></br>{`
`}{`University of British Columbia`}</p>
    <p>{`Finland`}<br parentName="p"></br>{`
`}{`University of Tampere`}</p>
    <p>{`France`}<br parentName="p"></br>{`
`}{`American University of Paris`}</p>
    <p>{`The Netherlands`}<br parentName="p"></br>{`
`}{`Breda University of Applied Sciences`}<br parentName="p"></br>{`
`}{`Erasmus University Rotterdam`}<br parentName="p"></br>{`
`}{`HU University of Applied Sciences Utrecht`}<br parentName="p"></br>{`
`}{`NHL Stenden University of Applied Sciences`}<br parentName="p"></br>{`
`}{`University of Amsterdam`}<br parentName="p"></br>{`
`}{`Vrije Universiteit Amsterdam`}</p>
    <p>{`Spain`}<br parentName="p"></br>{`
`}{`IE University`}</p>
    <p>{`United Kingdom`}<br parentName="p"></br>{`
`}{`Birmingham City University`}<br parentName="p"></br>{`
`}{`Brunel University`}<br parentName="p"></br>{`
`}{`City, University of London`}<br parentName="p"></br>{`
`}{`Kingston University London`}<br parentName="p"></br>{`
`}{`London Metropolitan University`}<br parentName="p"></br>{`
`}{`London School of Economics`}<br parentName="p"></br>{`
`}{`Met Film School`}<br parentName="p"></br>{`
`}{`New Castle University`}<br parentName="p"></br>{`
`}{`Oxford Brookes University`}<br parentName="p"></br>{`
`}{`Queen Mary University of London`}<br parentName="p"></br>{`
`}{`Royal Holloway, University of London`}<br parentName="p"></br>{`
`}{`University of Bath`}<br parentName="p"></br>{`
`}{`University of Birmingham`}<br parentName="p"></br>{`
`}{`University of Cambridge`}<br parentName="p"></br>{`
`}{`University College London`}<br parentName="p"></br>{`
`}{`University of Glasgow`}<br parentName="p"></br>{`
`}{`University of Greenwich`}<br parentName="p"></br>{`
`}{`University of Leeds`}<br parentName="p"></br>{`
`}{`University of Sheffield`}<br parentName="p"></br>{`
`}{`University of St. Andrews`}<br parentName="p"></br>{`
`}{`University of Surrey`}<br parentName="p"></br>{`
`}{`University of South Hampton`}<br parentName="p"></br>{`
`}{`University of Warwick`}<br parentName="p"></br>{`
`}{`University of Westminster `}</p>
    <p>{`United States of America`}<br parentName="p"></br>{`
`}{`American University`}<br parentName="p"></br>{`
`}{`George Washington University`}<br parentName="p"></br>{`
`}{`Lewis & Clark College`}<br parentName="p"></br>{`
`}{`Michigan State University`}<br parentName="p"></br>{`
`}{`Oregon State University`}<br parentName="p"></br>{`
`}{`Pasadena City College`}<br parentName="p"></br>{`
`}{`Rowan University`}<br parentName="p"></br>{`
`}{`Seattle University`}<br parentName="p"></br>{`
`}{`The New School`}<br parentName="p"></br>{`
`}{`University of Hawaii at Manoa`}<br parentName="p"></br>{`
`}{`University of Maryland`}<br parentName="p"></br>{`
`}{`University of Puget Sound`}<br parentName="p"></br>{`
`}{`University of San Francisco`}<br parentName="p"></br>{`
`}{`University of Washington`}<br parentName="p"></br>{`
`}{`Vassar College`}<br parentName="p"></br>{`
`}{`Virginia Commonwealth University`}<br parentName="p"></br>{`
`}{`Western Washington University	`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      